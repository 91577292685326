<template>
  <div class="page">
    <v-list
      ref="list"
      :isAdd="false"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
      :isOperateColumn="false"
    >
      <template #headBtnSlot>
        <v-button text="返回" @click="back"></v-button>
      </template>
      <template #searchSlot>
        <!-- <v-select
          clearable
          :options="list"
          v-model="searchParam.girdName"
          @change="$refs.list.search()"
          label="网格长"
          placeholder="请选择网格长"
        /> -->

        <v-input
          clearable
          v-model="searchParam.girdName"
          label="网格长"
          placeholder="请输入网格长姓名"
        />
        <!-- <v-select
          clearable
          :options="list"
          v-model="searchParam.str1"
          @change="$refs.list.search()"
          label="微网格长"
          placeholder="请选择微网格长"
        /> -->
      </template>
    </v-list>
  </div>
</template>

<script>
import { getFaildMobilesURL } from "./api";

export default {
  name: "notConnectedList",
  data() {
    return {
      list: [],
      searchParam: {
        id: "",
        girdName: null, //网格长
        // str2: null, //微网格长
      },
      tableUrl: getFaildMobilesURL,
      headers: [
        {
          prop: "userName",
          label: "姓名",
        },
        {
          prop: "phone",
          label: "手机号",
        },
        {
          prop: "girdName",
          label: "网格长",
        },
        // {
        //   prop: "",
        //   label: "微网格长",
        // },
        {
          prop: "address",
          label: "地址",
        },
      ],
    };
  },
  created() {
    this.searchParam.id = this.$route.query.id;
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
</style>
